// paper & background
$paper: #ffffff;

// primary
$primaryMain: #07514b;
$primaryLight: #e1f3f4;

// secondary
$secondaryMain: #07514b;
$secondaryLight: #e1f3f4;

// success Colors
$successLight: #e6f8f1;
$success200: #69f0ae;
$successMain: #00e676;
$successDark: #01bc82;

// error
$errorLight: #f7d5d5;
$errorMain: #f44336;
$errorDark: #c62828;

// warning
$warningLight: #fff8e1;
$warningMain: #ffe57f;
$warningDark: #ffc107;

// grey
$grey50: #f8fafc;
$grey100: #eef2f6;
$grey200: #e3e8ef;
$grey300: #cdd5df;
$grey500: #697586;
$grey600: #4b5565;
$grey700: #364152;
$grey900: #121926;

//new colors according to stage in circular progress bar
$stage1: #3641b7;
$stage2: #7f0ec4;
$stage3: #de6004;
$stage4: #ffb904;
$maxxx: #189e1d;
$catWhite: #f5f5f5;

$stage1_2inner: #ffb904;
$stage3_4inner: #f5f5f5;
$stage_max_inner: #ffb904;

// $razzmatazz: #eb1566;
// $yellow: #f7cb2c;
// $paleGreen: #4eca64;
// $catWhite: #f5f5f5;
// $blue: #0c45ad;

$circular_progress_1_first_color: #e6aac3;
$circular_progress_1_second_color: #eac5d6;
$circular_progress_1_third_color: #eac5d6;

// $circular_progress_2_first_color: #d9d11d61;
// $circular_progress_2_second_color: #e7eb154d;
// $circular_progress_2_third_color: #e7eb1533;

// $circular_progress_3_first_color: #b0caff;
// $circular_progress_3_second_color: #c5d7f7;
// $circular_progress_3_third_color: #dae5f6;

// $circular_progress_4_first_color: #ade9c3;
// $circular_progress_4_second_color: #c7e6d0;
// $circular_progress_4_third_color: #d3eddc;

// ==============================|| JAVASCRIPT ||============================== //

:export {
  // paper & background
  paper: $paper;

  // primary
  primaryLight: $primaryLight;
  primaryMain: $primaryMain;

  //secondary
  secondaryMain: $secondaryMain;
  secondaryLight: $secondaryLight;

  // success
  successLight: $successLight;
  success200: $success200;
  successMain: $successMain;
  successDark: $successDark;

  // error
  errorLight: $errorLight;
  errorMain: $errorMain;
  errorDark: $errorDark;

  // warning
  warningLight: $warningLight;
  warningMain: $warningMain;
  warningDark: $warningDark;

  // grey
  grey50: $grey50;
  grey100: $grey100;
  grey200: $grey200;
  grey300: $grey300;
  grey500: $grey500;
  grey600: $grey600;
  grey700: $grey700;
  grey900: $grey900;

  //linear progress bar
  stage1: $stage1;
  stage2: $stage2;
  stage3: $stage3;
  stage4: $stage4;
  maxxx: $maxxx;
  catWhite: $catWhite;

  stage1_2inner: $stage1_2inner;
  stage3_4inner: $stage3_4inner;
  stage_max_inner: $stage_max_inner;

  //circular progress bar
  circular_progress_1_first_color: $circular_progress_1_first_color;
  circular_progress_1_second_color: $circular_progress_1_second_color;
  circular_progress_1_third_color: $circular_progress_1_third_color;
}
