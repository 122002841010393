@import 'themes-vars.module.scss';

:root {
  --toastify-color-info: #3bafda;
  --toastify-color-error: #eb1717;
  --toastify-color-success: #8cc152;
}
.secondary-main-tint {
  filter: invert(24%) sepia(8%) saturate(5355%) hue-rotate(128deg) brightness(95%) contrast(94%);
}

.action-table-header {
  text-align: center;
}

.serial-no-data-col {
  width: 100px;
  text-align: left !important;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: $primaryMain;
  border-style: dashed;
  background-color: #fafafa;
  color: $primaryMain;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.menu-item-divider {
  margin-top: 8px;
  margin-bottom: 8px;
}
